import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "./LandingPage.css";
import "../Student/Components/ResponsiveCss/ResponsiveCss.css";
import Partners from "../Employer/Component/Landingpage/Partners";
// import Footer from "../Employer/Component/Footer/Footer";
import hero from "../Student/Assets/home.png";
import { GrNext, GrPrevious } from "react-icons/gr";
import Login from '../Student/Components/Signin';
import Footer from "../Student/Components/Footer";

const LandingPage = () => {
  const [jobs, setJobs] = useState([]);

  const carouselRef = useRef(null);
  const carouselsRef = useRef(null);
  const [loginStudentPopup, setLoginStudentPopup] = useState(false);


  useEffect(() => {
    // Fetch data from the API
    fetch("https://backend.internsbee.in/api/postinternship/internship/recentpost")
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setJobs(data.data);
          console.log(jobs)
        }
      })
      .catch(error => console.error("Error fetching jobs:", error));
  }, []);

  const handleNextSlide = () => {
    const carousel = carouselRef.current;
    if (carousel) {
      const scrollWidth = carousel.scrollWidth;
      const scrollLeft = carousel.scrollLeft;
      const clientWidth = carousel.clientWidth;
      const maxScrollLeft = scrollWidth - clientWidth;
      const newScrollLeft = Math.min(scrollLeft + clientWidth, maxScrollLeft);

      carousel.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
    }
  };

  const handlePreviousSlide = () => {
    const carousel = carouselRef.current;
    if (carousel) {
      const scrollLeft = carousel.scrollLeft;
      const newScrollLeft = Math.max(scrollLeft - carousel.clientWidth, 0);

      carousel.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
    }
  };

  const handleNextCarouselSlide = () => {
    const carousel = carouselsRef.current;
    if (carousel) {
      const scrollWidth = carousel.scrollWidth;
      const scrollLeft = carousel.scrollLeft;
      const clientWidth = carousel.clientWidth;
      const maxScrollLeft = scrollWidth - clientWidth;
      const newScrollLeft = Math.min(scrollLeft + clientWidth, maxScrollLeft);

      carousel.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
    }
  };

  const handlePreviousCarouselSlide = () => {
    const carousel = carouselsRef.current;
    if (carousel) {
      const scrollLeft = carousel.scrollLeft;
      const newScrollLeft = Math.max(scrollLeft - carousel.clientWidth, 0);

      carousel.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
    }
  };

  const openLoginStudentPopup = () => {
    // setLoginStudentPopup(true);
    alert("Please Login");
  };

  const closeLoginStudentPopup = () => {
    setLoginStudentPopup(false);
  };

  return (
    <div className="flex flex-col h-screen">
      <section className="landingpage-hero-content text-gray-600 body-font">
        <div className="container mx-auto flex  md:flex-row flex-col items-center">
          {/* <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-2 md:mb-0 items-center text-center">
            <h1 className="landingpage-title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 text-left">
              Your Journey to
              <br className="hidden lg:inline-block" />
              Success Begins Here!!!
            </h1>
          </div> */}
          <div className=" landingpage-hero-image lg:w-full md:w-1/2 w-3/6">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src={hero}
            />
          </div>
        </div>
      </section>


      {/* Section 3: Latest Jobs */}
      <section
        className="landingpage-latest-jobs flex-1"
        id="dream-career-section"
      >
        <h1 className="heading">Latest Internships on InternsBee</h1>

        {/* Popular Categories */}
        {/* card-landing-page Carousel */}
        <div className="carousel-container mt-5 overflow-hidden">
          <div className="carousel " ref={carouselRef}>
            {jobs.map((job) => (
              <div key={job._id} className="card-landing-page">
                <div className="max-w-md mx-auto bg-white rounded-md overflow-hidden shadow-lg">
                  <div className="cardpadd p-4">
                    <h2 className="text-xl font-semibold mb-2">{job.job_Title}</h2>
                    <p className="text-gray-700 mb-2">{job.company_Name}</p>
                    <hr className="my-2" />
                    <div className="cardlowercont flex items-center justify-between mt-2">
                      <div>
                        <p className="text-gray-600">Location: {job.location}</p>
                        <p className="text-gray-600">Stipend: {job.stipend}</p>
                        <p className="text-gray-600">Duration: {job.position}</p>
                      </div>
                      <button
                        onClick={openLoginStudentPopup}
                        className=" text-black px-4 py-2 rounded-md focus:outline-none"
                        style={{ backgroundColor: "#FFBD59" }}
                      >
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {loginStudentPopup && (
              
              <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                <div className="">
                  <Login onClose={closeLoginStudentPopup} />
                  
                </div>
              </div>
            )}
          </div>
          <div className="NextPriv text-3xl flex items-center justify-center gap-12 mb-20">
            <button onClick={handlePreviousSlide}>
              <GrPrevious />
            </button>
            <button onClick={handleNextSlide}>
              <GrNext />
            </button>
          </div>
        </div>
      </section>

      <section className="landingpage-top-companies" id="dream-career-section">
        <div className="text-center">
          <h1 className="text-2xl font-semibold mb-6 text-center">
            Top companies trust us
          </h1>
        </div>
        <Partners />

        <section className="landingpage-statistics" id="dream-career-section">
          <div className="container px-5 py-24 mx-auto">
            <div className="flex flex-wrap -m-4 text-center justify-center">
              <div className="p-4 sm:w-1/4 w-1/2">
                <h2 className="title-font font-medium sm:text-4xl text-3xl text-sky-900">
                  500+
                </h2>
                <p className="leading-relaxed">Companies hiring</p>
              </div>
              <div className="p-4 sm:w-1/4 w-1/2">
                <h2 className="title-font font-medium sm:text-4xl text-3xl text-sky-900">
                  200+
                </h2>
                <p className="leading-relaxed">New openings everyday</p>
              </div>
              <div className="p-4 sm:w-1/4 w-1/2">
                <h2 className="title-font font-medium sm:text-4xl text-3xl text-sky-900">
                  2000+
                </h2>
                <p className="leading-relaxed">active students</p>
              </div>
            </div>
          </div>
        </section>
      </section>

      <div className="landingpage-footer">
        {/* <Footer /> */}
        <Footer/>
      </div>
    </div>
  );
};

export default LandingPage;
