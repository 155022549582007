
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Assets/yellow_header1.png";
import './Newnavbar.css'

const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state
  };

  const emploeLoggedOut = () => {
    localStorage.clear();
    navigate("/"); // Redirect to login page
  };

  return (
    <nav className="flex items-center justify-between p-2 h-auto  w-auto"
    style={{ backgroundColor: "#FFBD59" }}>
       <Link to={"/employer/home"}>
        <div className="flex items-center">
          <img
             src={logo}
            alt="Logo"
            className="w-14 rounded-none main-navbar-for-the-employeee-page-internbee"
              style={{ width: "11rem", height: "4rem" }}
           />
         </div>
       </Link>

      {/* Hamburger menu for mobile */}
      <button className="lg:hidden" onClick={handleToggle}>
        <div className={`space-y-2 ${isOpen ? "open" : ""}`}>
          <span className="block w-8 h-0.5 bg-black"></span>
          <span className="block w-8 h-0.5 bg-black"></span>
          <span className="block w-8 h-0.5 bg-black"></span>
        </div>
      </button>

      {/* Navbar links */}
      <div className={`lg:flex items-center space-x-6 ${isOpen ? "active  " : "hidden"} lg:block`}>
        <ul className="flex flex-col lg:flex-row lg:space-x-6 space-y-4 lg:space-y-0 text-lg font-bold">
          <li>
            <Link to="/employer/home" className="text-black hover:text-gray-700">
              Employers
            </Link>
          </li>
          <li>
            <Link to="/employer/packages" className="text-black hover:text-gray-700">
              Plans & Pricing
            </Link>
          </li>
          <li className="relative group">
            <button className="text-black hover:text-gray-700">
              Profile
            </button>
            {/* Profile Dropdown */}
            <ul className=" absolute hidden bg-black text-white p-2 space-y-2 rounded-md transition duration-300 ease-in-out group-hover:block w-40">
                  <li>
                    <Link to="/employer/view-profile-page" className="flex items-center">
                      <span className="mr-2">👤</span> View Profile
                    </Link>
                  </li>
                  <li>
                    <Link to="/employer/accountsetting" className="flex items-center">
                      <span className="mr-2">🔧</span> Settings
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/employer/packages" className="flex items-center">
                      <span className="mr-2">🔄</span> Subscriptions
                    </Link>
                  </li>
                  <li>
                    <Link to="/employer/faq" className="flex items-center">
                      <span className="mr-2">❓</span> Help
                    </Link>
                  </li> */}
                </ul>
          </li>
        
        <button
          onClick={emploeLoggedOut}
         className="bg-black text-white rounded-md px-4 py-2 hover:bg-white hover:text-black max-w-xs transition duration-300 ease-in-out hover:scale-110"
        >
          Log out
        </button>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
