import React, { useEffect, useState } from "react";
import Internal_Navbar from "./UpdatedNav/Internal_Navbar";
import Footer from '../Components/Footer';
import axios from 'axios';
import { config } from "../service/service";

const ViewResume = () => {
  const [resumeData, setResumeData] = useState({});
  const [isExist, setExist] = useState(false);
  const [isExistmsg, setExistmsg] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchResumeData = async () => {
    try {
      const studentId = localStorage.getItem("userId");
      const response = await axios.get(`${config.backend_url}/api/newresume/student/${studentId}`);
      console.log(response.data); // Check the structure of the response data
      setLoading(false);

      if (response.status === 200 && response.data) {
        setResumeData(response.data); // Ensure response contains necessary properties
        setExist(true);
      } else {
        setExistmsg("Resume data not found");
      }
    } catch (error) {
      console.error('Error fetching resume data:', error);
      setExistmsg('Failed to fetch resume data.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResumeData();
  }, []);

  // Destructure data with defaults to handle potential undefined values
  const { personalInformation = {}, education = {}, experience = {}, portfolio = {} } = resumeData || {};

  return (
    <div className="bg-gray-50">
      <Internal_Navbar />
      {
        loading ? (
          <div>Loading...!</div>
        ) : isExist ? (
          <div className="flex justify-center relative top-14 mb-32">
            <div className="max-w-4xl w-full bg-white p-8 shadow-md rounded-lg">
              <h1 className="text-2xl font-bold mb-6">Resume</h1>

              <div className="mb-6">
                <h2 className="text-xl font-semibold">Personal Information</h2>
                <p><strong>First Name:</strong> {personalInformation.firstName || 'N/A'}</p>
                <p><strong>Last Name:</strong> {personalInformation.lastName || 'N/A'}</p>
                <p><strong>Email Address:</strong> {personalInformation.email || 'N/A'}</p>
                <p><strong>Address:</strong> {personalInformation.address || 'N/A'}</p>
                <p><strong>Phone Number:</strong> {personalInformation.phoneNumber || 'N/A'}</p>
                <p><strong>Gender:</strong> {personalInformation.gender || 'N/A'}</p>
                <p><strong>Current Salary:</strong> {personalInformation.currentSalary || 'N/A'}</p>
                <p><strong>Expectation:</strong> {personalInformation.expectation || 'N/A'}</p>
                <p><strong>Career Profile:</strong> {personalInformation.skills || 'N/A'}</p>
                <p><strong>Skills:</strong> {personalInformation.skills ? personalInformation.skills.join(', ') : 'N/A'}</p>
                <p><strong>Level:</strong> {personalInformation.level || 'N/A'}</p>
              </div>

              <div className="mb-6">
                <h2 className="text-xl font-semibold">Education</h2>
                <p><strong>Degree:</strong> {education.education || 'N/A'}</p>
                <p><strong>Institute:</strong> {education.institute || 'N/A'}</p>
                <p><strong>Pass Out Year:</strong> {education.passOutYear || 'N/A'}</p>
              </div>

              <div className="mb-6">
                <h2 className="text-xl font-semibold">Experience</h2>
                <p><strong>Company Name:</strong> {experience.companyName || 'N/A'}</p>
                <p><strong>Designation:</strong> {experience.designation || 'N/A'}</p>
                <p><strong>Location:</strong> {experience.location || 'N/A'}</p>
                <p><strong>About Company:</strong> {experience.aboutCompany || 'N/A'}</p>
              </div>

              <div className="mb-6">
                <h2 className="text-xl font-semibold">Portfolio</h2>
                <p><strong>Project Name:</strong> {portfolio.projectname || 'N/A'}</p>
                <p><strong>Project Description:</strong> {portfolio.projectDescription || 'N/A'}</p>
              </div>
            </div>
          </div>
        ) : (
          <div> 
            <h1 className="h-[55vh] flex justify-center items-center">{isExistmsg}</h1>
          </div>
        )
      }
      <Footer />
    </div>
  );
};

export default ViewResume;
