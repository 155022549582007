import React from "react";

import {  Routes, Route } from "react-router-dom";
import Sidebar from "./Components/Sidebar";
import Home from "./Components/Home";
import Home1 from "./Components/Home1";
import Free from "./Components/Plans/Free";
import Month from "./Components/Plans/month";
import Resume from "./Components/Resume";
import Registration from "./Components/Registration";
import Signin from "./Components/Signin";
import Profile from "./Components/Profile";
import Internship from "./Components/Internship";
import Footer from "./Components/Footer";
// import Companies from "./Components/Companies/Companies";
import Blogs from "./Components/Blogs/Blogs";
import ViewProfile from "./Components/ViewProfile/ViewProfile";
import UploadImagePdf from "./Components/UploadImagePdf/UploadImage";
import ApplyInternship from "./Components/ApplyInternship/ApplyInternship";
import AppliedIntersnship from "./Components/ApplyInternship/AppliedIntersnship";
import FreePlan from "./Components/StudentPackages/FreePlan";
import MonthlyPlan from "./Components/StudentPackages/MonthlyPlan";
import EnquiryPage from "./Components/ApplyInternship/EnquiryPage";
import PolicyTermsCondition from "./Components/PrivacyPolicy/PolicyTermsCondition";
import MonthlyPackages from "./Components/StudentPackages/MonthlyPlan";
import SubscribePackage from "./Components/StudentPackages/SubscribePackage";
import EnterOtpPage from "./Components/EnterOtpPage/EnterOtpPage";
import FAQPage from "./Components/Faqs/Faqs";
import AboutUs from "./Components/AboutUs/AboutUs";
import ContactUs from "./Components/Contact/Contactus";
import Navbar from "./Components/Navbar";
import Internal_Navbar from "./Components/InternalNavbar";
import SignupOtp from "./Components/SignupOtp/SignupOtp";
import Alert from "./Components/Alert/Alert";
import Privacy from "./Components/Privacy/Privacy";
import Terms from "./Components/Terms/Terms";
import Refund from "./Components/Refund/Refund";
import Applied_Intern_Internal_Navbar from "./Components/Test/Applied_Intern_Internal_Navbar";
import QuickNav from "./Components/QuickNav";
import ApplyInternshipLanding from "./Components/ApplyInernshipLanding/ApplyInternshipLanding";
import ProfileConfirmMessage from "./Components/ProfileConfirmMessage/ProfileConfirmMessage";
import RestPass from "./Components/RestPass/ResetPass";
import VerifyOtp from "./Components/RestPass/VerifyOtp";
import ViewResume from './Components/ViewResume';
import TPO from './Components/TPO/TPO';
import LandingInternship from './Components/LandingInternship/LandingInternship';
import FAQ from './Components/FAQ/FAQ';
import HelpCenter from './Components/HelpCenter/HelpCenter';
import PrivacyPolicyTerms from "./Components/PrivacyPolicy/PrivacyPolicyTerms";
function StudentRoutes() {

  return (
    
      <Routes>
      <Route path="/contactus" element={<ContactUs />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/month" element={<Month />} />
        <Route path="/quicknav" element={<QuickNav />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/enterotp" element={<EnterOtpPage />} />
        {/*  */}
        <Route path="/resume" element={<Resume />} />
        <Route path="/login" element={<Signin />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/internship" element={<Internship />} />
        <Route path="/footer" element={<Footer />} />
        <Route path="/nav" element={<Internal_Navbar />} />
        <Route path="/viewprofile" element={<ViewProfile />} />
        <Route path="/uploadimage" element={<UploadImagePdf />} />
        <Route path="/viewresume" element={<ViewResume />} />
        {/* <Route path="/TPO" element={<TPO />} /> */}
        {/* <Route path="/LandingInternship" element={<LandingInternship />} /> */}
        <Route path="/faq" element={<FAQ />} />
        <Route path="/helpcenter" element={<HelpCenter />} />

        <Route
          path="/apply-internship/:internshipId"
          element={<ApplyInternship />}
        />
        <Route
          path="/applyinternshiplanding/:internshipId"
          element={<ApplyInternshipLanding />}
        />
        <Route path="/free" element={<Free />} />
        <Route path="/applied-internship" element={<AppliedIntersnship />} />
        <Route path="/freeplan" element={<FreePlan />} />
        <Route path="/monthlyplan" element={<MonthlyPackages />} />
        <Route path="/subscribepackage" element={<SubscribePackage />} />
        <Route path="/studentEnquiry" element={<EnquiryPage />} />
        <Route path="/privacypolicy" element={<PolicyTermsCondition />} />
        <Route path="/faqs" element={<FAQPage />} />
        <Route path="/about" element={<AboutUs />} />

        <Route path="/signupotp" element={<SignupOtp />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/Test" element={<Applied_Intern_Internal_Navbar />} />
        <Route path="/profileconfirm" element={<ProfileConfirmMessage />} />
        <Route path ="/policiesTerms" element={<PrivacyPolicyTerms/>} />
        {/* <Route path="/resetpass" element={<RestPass />} />
        <Route path="/changepassword/:token" element={<VerifyOtp />} /> */}

      </Routes>
   


  );
}

export default StudentRoutes;
