import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import Navbar from "./../Navbar/Navbar";
import "../ResponsiveCss/ResponsiveCss.css";
import { getEmployee, updateEmployee } from "../../apis/AuthAPI";

const ViewProfilePage = () => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [profileData, setProfileData] = useState({
    empName: "",
    password: "",
    email: "",
    number: "",
    companyAddress: "",
    description: "",
    selectedFile: "",
    company_website_url: "",
    cin_number: "",
  });

  const [subscriptionData, setSubscriptionData] = useState({});
  const [editedData, setEditedData] = useState({ ...profileData });
  const [error, setError] = useState({});

  const convertToDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const openEditModal = () => {
    setEditedData({ ...profileData });
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchProfileData = async () => {
    try {
      const response = await getEmployee();
      const data = response.data.data;
      setProfileData(data);
      setSubscriptionData({
        expiryDate: convertToDate(data.package.end_date),
        plan: data.package.type,
        status: data.package.active ? "Subscribed" : "Not Subscribed",
        ResumeView: data.ResumeView,
        searches: data.searches,
        internshipEnquiry: data.internshipEnquiry,
        verifiedApplication: data.verifiedApplication,
      });
      setEditedData(data);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

const validate =() =>{
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const cinRegex = /^[A-Z]{1}\d{5}[A-Z]{2}\d{4}[A-Z]{3}\d{6}$/;
  const phoneRegex = /^\d{10}$/;
  const errors = {};

  if(!editedData.empName.trim()){
    errors.empName = "employe name is required";
  }
  if(!editedData.email.trim()){
    errors.email = "email is required";
  } else if(!emailRegex.test(editedData.email)){   
      errors.email = "A valid email is required";
  }
  if(!editedData.number.trim()){
    errors.number = "number is required";
  }
  else if(editedData.number.length < 10 || editedData.number.length > 10){   
    errors.number = "number must be exactly 10 digits";
}

if(!editedData.companyAddress.trim()){
  errors.companyAddress = "Company address is required";
}

if(!editedData.description.trim()){
  errors.description = "Description is required";
}

if(!editedData.company_website_url.trim()){
  errors.company_website_url = "company website url is required";
}

if(!editedData.cin_number.trim()){
  errors.cin_number = "CIN number is required";
}else if(!cinRegex.test(editedData.cin_number)){
  errors.cin_number = "CIN number is Invalid";
}


  setError(errors);

  if(Object.keys(errors).length > 0){
    return false;
  }
  else{
    return true;
  }

}
 
  const saveEditedData = async () => {
    
    if(!validate()){
      return;
    } 

    try {
      const response = await updateEmployee(editedData);
      
      // Update the profile data directly without refetching
      setProfileData(editedData);

      alert("Information Updated Successfully");
      closeEditModal();
    } catch (error) {
      console.error("Error updating profile data:", error);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  return (
    <>
      <Navbar />

      <div className="displayBlock flex gap-10">
        <Sidebar />
        {/* Employee Information */}
        <div className="mx-auto mt-10 sm:mt-20 md:mt-28 sm:mb-20 md:mb-28 p-4 sm:p-6 bg-white rounded-xl shadow-md border-1 border-amber-500 h-[40%] md:h-[40%] max-w-lg md:max-w-2xl">
          <div className="text-center">
            <h2 className="text-xl sm:text-2xl font-bold">Profile</h2>
          </div>
          <div className="mt-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm text-gray-600">Name</label>
                <p>{profileData.empName}</p>
              </div>
              <div>
                <label className="block text-sm text-gray-600">
                  Company Email
                </label>
                <p>{profileData.email}</p>
              </div>
              <div>
                <label className="block text-sm text-gray-600">
                  Phone Number
                </label>
                <p>{profileData.number}</p>
              </div>
              <div>
                <label className="block text-sm text-gray-600">Address</label>
                <p>{profileData.companyAddress}</p>
              </div>
              <div className="md:col-span-2 mt-4">
                <label className="block text-sm text-gray-600">
                  Company Description
                </label>
                <p>{profileData.description}</p>
              </div>
              <div>
                <label className="block text-sm text-gray-600">
                  Company Website
                </label>
                <p>
                {profileData.company_website_url}
                </p>
              </div>
              <div>
                <label className="block text-sm text-gray-600">CIN No</label>
                <p>{profileData.cin_number}</p>
              </div>
            </div>
          </div>

          <div className="flex justify-center mt-6">
          <button
  onClick={openEditModal}
  className="p-2 bg-amber-500 text-white rounded-md hover:bg-amber-600 focus:outline-none focus:ring focus:ring-amber-200"
>
  Edit Profile
</button>
          </div>
        </div>
        {/* Subscription Information */}
        <div className="mx-auto mt-10 mb-2 sm:mt-20 md:mt-28 sm:mb-20 md:mb-28 p-4 sm:p-6 bg-white rounded-xl shadow-md border-1 border-amber-500 h-[40%] md:h-[40%] max-w-lg md:max-w-2xl">
          <div className="text-center">
            <h2 className="text-xl sm:text-2xl font-bold">
              Subscription Information
            </h2>
          </div>
          <div className="mt-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm text-gray-600">Status</label>
                <p>{subscriptionData.status}</p>
              </div>

              {subscriptionData.status ? (
                <>
                  <div>
                    <label className="block text-sm text-gray-600">Plan</label>
                    <p>{subscriptionData.plan}</p>
                  </div>
                  <div>
                    <label className="block text-sm text-gray-600">
                      Expiry Date
                    </label>
                    <p>
                      {subscriptionData.expiryDate === null
                        ? subscriptionData.expiryDate
                        : "Not Subscribed"}
                    </p>
                  </div>
                  <div>
                    <label className="block text-sm text-gray-600">
                      Searches
                    </label>
                    <p>{subscriptionData.searches}</p>
                  </div>
                  <div>
                    <label className="block text-sm text-gray-600">
                      Internship Enquiry
                    </label>
                    <p>{subscriptionData.internshipEnquiry}</p>
                  </div>
                  <div>
                    <label className="block text-sm text-gray-600">
                      Verified Application
                    </label>
                    <p>{subscriptionData.verifiedApplication}</p>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div>
                <label className="block text-sm text-gray-600">Resume View</label>
                <p>{subscriptionData.ResumeView}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Modal */}
      {isEditModalOpen && (
  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
    <div className="bg-white rounded-md shadow-md w-[90%] md:w-[70%] lg:w-[50%] h-[90%] overflow-y-auto p-6">
      <h2 className="text-lg font-semibold mb-4">Edit Profile</h2>
      <form>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="mb-4">
            <label className="block text-sm text-gray-600">Name</label>
            <input
              type="text"
              name="empName"
              value={editedData.empName}
              onChange={handleInputChange}
              required
              className="border border-gray-300 p-2 w-full rounded-md"
            />
            {error.empName && <span className="text-red-400">{error.empName}</span>}
          </div>

          <div className="mb-4">
            <label className="block text-sm text-gray-600">Company Email</label>
            <input
              type="text"
              name="email"
              value={editedData.email}
              onChange={handleInputChange}
              required
              className="border border-gray-300 p-2 w-full rounded-md"
            />
            {error.email && <span className="text-red-400">{error.email}</span>}
          </div>

          <div className="mb-4">
            <label className="block text-sm text-gray-600">Phone Number</label>
            <input
              type="number"
              name="number"
              value={editedData.number}
              onChange={handleInputChange}
              required
              className="border border-gray-300 p-2 w-full rounded-md"
            />
            {error.number && <span className="text-red-400">{error.number}</span>}
          </div>

          <div className="mb-4">
            <label className="block text-sm text-gray-600">Address</label>
            <input
              type="text"
              name="companyAddress"
              value={editedData.companyAddress}
              onChange={handleInputChange}
              required
              className="border border-gray-300 p-2 w-full rounded-md"
            />
            {error.companyAddress && <span className="text-red-400">{error.companyAddress}</span>}
          </div>

          <div className="md:col-span-2 mb-4">
            <label className="block text-sm text-gray-600">Company Description</label>
            <textarea
              name="description"
              value={editedData.description}
              onChange={handleInputChange}
              required
              className="border border-gray-300 p-2 w-full rounded-md"
            />
            {error.description && <span className="text-red-400">{error.description}</span>}
          </div>

          <div className="mb-4">
            <label className="block text-sm text-gray-600">Company Website</label>
            <input
              type="text"
              name="company_website_url"
              value={editedData.company_website_url}
              onChange={handleInputChange}
              required
              className="border border-gray-300 p-2 w-full rounded-md"
            />
            {error.company_website_url && <span className="text-red-400">{error.company_website_url}</span>}
          </div>

          <div className="mb-4">
            <label className="block text-sm text-gray-600">CIN No</label>
            <input
              type="text"
              name="cin_number"
              value={editedData.cin_number}
              onChange={handleInputChange}
              className="border border-gray-300 p-2 w-full rounded-md"
            />
            {error.cin_number && <span className="text-red-400">{error.cin_number}</span>}
          </div>
        </div>

        <div className="flex justify-end mt-4">
          <button
            type="button"
            onClick={saveEditedData}
            className="p-2 bg-amber-500 text-white rounded-md mr-2 hover:bg-amber-600 focus:outline-none focus:ring focus:ring-amber-200"
          >
            Save
          </button>
          <button
            type="button"
            onClick={closeEditModal}
            className="p-2 bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:ring focus:ring-gray-200"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
)}

      <Footer />
    </>
  );
};

export default ViewProfilePage;
