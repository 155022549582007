import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaRegClock, FaMoneyBill, FaMapMarkerAlt } from "react-icons/fa";
import axios from "axios";
import Internal_Navbar from "../UpdatedNav/Internal_Navbar.js";
import "../ApplyInternship/Applyintern.css";
import Footer from "../Footer.js";
import { config } from "../../service/service.js";

const ApplyInternship = () => {
  const { internshipId } = useParams();
  const [internship, setInternship] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInternshipData = async () => {
      try {
        const response = await fetch(
          `${config.backend_url}/api/postinternship/${internshipId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setInternship(data);
      } catch (error) {
        console.error("Error fetching internship data:", error);
      }
    };

    fetchInternshipData();
  }, [internshipId]);

  if (!internship) {
    return <p>Loading...</p>;
  }

  const handleApply = async () => {
    setIsSubmitting(true);

    try {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");

      let count = JSON.parse(localStorage.getItem('count')) || { verified_apply: 0 };

      // Check if student ID exists in studentsdetails API
      const formData = {
        postId: internshipId,
        InternId: userId,
      };

      const response = await axios.post(
        `${config.backend_url}/api/applyinternship/`,
        formData
      );

      if (response.status === 202) {
        console.log(response);
        if (response.data.message === 'Complete the Profile to apply internship.') {
          alert(response.data.message);
          return navigate("/student/profile");
        }
        alert(response.data.message);
        navigate('/student/freeplan');
      } else if (response.status === 200) {
        const updatedUserData = {
          verified_apply: count.verified_apply - 1
        };

        await axios.patch(
          `${config.backend_url}/api/newstudents/update`,
          updatedUserData,
          {
            headers: {
              Authorization: token
            }
          }
        );

        // Updating the local storage value
        localStorage.removeItem('count');
        count.verified_apply = updatedUserData.verified_apply;
        localStorage.setItem('count', JSON.stringify(count));

        alert("Application submitted successfully!");
        navigate('/student/applied-internship');
      } else {
        alert("Error: Unable to apply for the internship");
      }
    } catch (error) {
      console.error("Error:", error.message);
      if (error.response) {
        console.log("Error details:", error.response.data);
        alert(error.response.data.message || "An error occurred");
      } else {
        alert("An error occurred");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const DateFormate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="bg-gray-50">
      <div>
        <Internal_Navbar />
      </div>
      <div className="apply-card mx-auto max-w-2xl p-6">
        <div className="card w-full rounded-md flex flex-grow items-center justify-center bg-white shadow-md overflow-hidden mt-20">
          <div className="flex-grow px-6 py-4">
            <h2 className="card-title text-2xl font-semibold text-gray-800">
              {internship.job_Title}
            </h2>
            <p className="card-company text-xl text-gray-700">
              Company Name: {internship.company_Name}
            </p>

            <div className="fild flex items-center my-4 gap-8">
              <div className="flex items-center p-2">
                <FaRegClock className="mr-2" />
                <p className="card-company text-xl text-gray-700">
                  Start Date: {DateFormate(internship.start_Date)}
                </p>
              </div>
              <div className="flex items-center p-2">
                <FaMoneyBill className="mr-2" />
                <p className="card-location text-xl text-gray-700">
                  &#x20B9;{internship.stipend}
                </p>
              </div>
              <div className="flex items-center p-2">
                <FaMapMarkerAlt className="mr-2" />
                <p className="card-duration text-xl text-gray-700">
                  {internship.location}
                </p>
              </div>
              <div className="flex items-center p-2">
                <FaRegClock className="mr-2" />
                <p className="card-duration text-xl text-gray-700">
                  End Date: {DateFormate(internship.end_Date)}
                </p>
              </div>
            </div>
            <div className="flex gap-32">
              <div>
                <p className="card-description text-base text-gray-700 mb-3">
                  Job Type: {internship.job_Type}
                </p>
              </div>
              <div className="flex items-center">
                <FaRegClock className="mr-2" />
                <p className="card-duration text-sm text-gray-700">
                  Duration: {internship.position}
                </p>
              </div>
            </div>
            <p className="card-description text-base text-gray-700 mb-3">
              {internship.job_Description}
            </p>
            <div className="justify-between items-center">
              <p className="card-skills text-base text-gray-700">
                Skills: {internship.skills}
              </p>

              <button
                onClick={handleApply}
                className="bg-amber-400 text-black p-2 rounded-lg w-24 mt-4 mx-auto"
                disabled={isSubmitting} // Disable button while submitting
              >
                {isSubmitting ? 'Submitting...' : 'Apply Now'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ApplyInternship;
