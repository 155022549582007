import React, { useState, useEffect } from "react";
import './ViewProfile.css'
import HomeNav from "../HomeNav/HomeNav";
import axios from "axios";
import { Link } from "react-router-dom";
import "../UpdatedNav/Internal_Navbar";
import Internal_Navbar from "../UpdatedNav/Internal_Navbar";
import "../ResponsiveCss/ResponsiveCss.css";
import Footer from './../Footer';
import { config } from "../../service/service";

const ViewProfile = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [keySkills, setSkills] = useState(userDetails?.keySkills || "");
  const [errors, setErrors] = useState({});
  

  
  const [isEdit, setIdEdit] = useState({
    personalInfo: false,
    experience: false,
    keySkills: false,
    projectDetails: false,
    educatinalDetails: false,
    personalDetails: false,
  });



  
 
  const changeHandler = (e) => {
    const{name, value} = e.target;
    setUserDetails({...userDetails, [name]:value});
  }


  const changeHandler2 = (e) => {
    const { value } = e.target;
  
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      keySkills: value // Updating keySkills with the new input value
    }));
  };

  
  const [detailExist, setDetailExist] = useState(false)
  const userId = localStorage.getItem("userId");


  const [subscriptionData, setSubscriptionData] = useState();


  const DateFormate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
};



  


  

  const editProfile = async () => {
   
    try {
      await axios.patch(`${config.backend_url}/api/studentsdetails/userId/${userId}`, userDetails);
      // setEditState(false);
      // Fetch the updated user details after a successful update
      console.log(userDetails)
      const response = await axios.get(`${config.backend_url}/api/studentsdetails/userId/${userId}`);
      setUserDetails(response.data);
      
    } catch (error) {
      console.error("Error editing details:", error);
    }
  };


  const getSubscriptionData = async () => {
    try {
      const res = await axios.get(`${config.backend_url}/api/newstudents/getuser`,
        {
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        }
      )
      console.log(res)
      const data = res.data.data
      let Subdata = {
        opportunities: data.opportunities,
        search: data.search,
        verified_apply: data.verified_apply,
        freemium:
        {
          active: data.freemium.active,
          startDate: data.freemium.startDate,
          endDate: data.freemium.endDate,
        },
        premium: {
          active: data.premium.active,
          startDate: data.premium.startDate,
          endDate: data.premium.endDate,
        },

      }
      setSubscriptionData(Subdata)

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    getSubscriptionData()
  },[]);

  useEffect(() => {
    console.log("Check")
    console.log(subscriptionData)
  }, [subscriptionData])
  

  useEffect(() => {
    fetchData()
  }, [userId]);

const fetchData = async()=>{
  try {
    const res = await axios.get(`${config.backend_url}/api/studentsdetails/userId/${userId}`)
    if(res.status === 200){
      setUserDetails(res.data)
      setDetailExist(true)
      console.log(userDetails)
      console.log(res.data)
    }
    
  } catch (error) {
    console.log(error)
  }

}

const handleCombinedClick = () => {
  handleSubmitProjectDetails(); // Call the submit function
  toggleEdit('projectDetails'); // Call the toggle edit function
};

// Function to handle toggling the edit mode
const toggleEdit = (section) => {
  setIdEdit((prevData) => ({
    ...prevData,
    keySkills: section === 'keySkills' ? !prevData.keySkills : prevData.keySkills,
  }));

  setIdEdit((prevState) => ({
    ...prevState,
    [section]: !prevState[section],
  }));
};




const handleSubmitProjectDetails = async (e) => {
  e.preventDefault(); // Prevent default form submission
  await editProfile(); // Call the editProfile function
};

const validateBirthdate = (birthdate) => {
  let errors = {};

  if (!birthdate) {
    errors.birthdate = "Date of Birth is required";
  } else {
    const inputDate = new Date(birthdate);
    const today = new Date();
    today.setHours(0, 0, 0, 0); 

    if (inputDate.toDateString() === today.toDateString()) {
      errors.birthdate = "Birthdate cannot be today.";
    } 

    else if (inputDate > today) {
      errors.birthdate = "Birthdate cannot be in the future.";
    }
  }

  return errors;
};



const handleSubmit = async (e) => {
  const errors = validateBirthdate(userDetails.birthdate);
  e.preventDefault(); // Prevent default form submission
  await editProfile();
  if (Object.keys(errors).length === 0) {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      keySkills: keySkills, 
    }));
    toggleEdit(); 
  } else {
    console.log(errors);
  }
};


useEffect(() => {
  // Restore skills from userDetails when it changes
  setSkills(userDetails?.keySkills || "");
}, [userDetails]);

const validateUserDetails = () => {
  const { firstName, lastName, permanentaddress, contact, experience, keySkills, projectSummary, projectName, education, instituteName, stream, passOutYear, percentage, languages, city, district, birthdate } = userDetails;
  if (!firstName || !lastName || !permanentaddress || !contact || !experience || !keySkills || !projectName || !projectSummary || !education || !instituteName || !stream || !passOutYear || !percentage || !birthdate || !district || !city || !languages) {
    alert("Blank data cannot be entered"); 
    return false;
  }
  return true;

};


  return (
    <>
      <div>
        <Internal_Navbar />
      </div>

      {/* subscription */}
      <div className="mx-auto mt-10 mb-2 sm:mt-20 md:mt-28 sm:mb-20 md:mb-28 p-4 sm:p-6 bg-white rounded-xl shadow-md border-2 border-amber-500 h-[40%] md:h-[40%] max-w-lg md:max-w-2xl">
        <div className="text-center">
          <h2 className="text-xl sm:text-2xl font-bold">
            Subscription Information
          </h2>
        </div>
        {
          subscriptionData ? 
          <div className="mt-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm text-gray-600">Status</label>
              <p>
                {console.log(subscriptionData)}
                {
                subscriptionData.freemium.active
                  ? "Freemium Active"
                  : subscriptionData.premium.active
                    ? "Premium Active"
                    : "No Active Subscription"}
              </p>
            </div>

            {/* Display Freemium Details if Active */}
            {subscriptionData.freemium.active && (
              <>
                <div>
                  <label className="block text-sm text-gray-600">Plan</label>
                  <p>Freemium</p>
                </div>
                <div>
                  <label className="block text-sm text-gray-600">Start Date</label>
                  <p>{DateFormate(subscriptionData.freemium.startDate)}</p>
                </div>
                <div>
                  <label className="block text-sm text-gray-600">Expiry Date</label>
                  <p>{DateFormate(subscriptionData.freemium.endDate)}</p>
                </div>
              </>
            )}

            {/* Display Premium Details if Active */}
            {subscriptionData.premium.active && (
              <>
                <div>
                  <label className="block text-sm text-gray-600">Plan</label>
                  <p>Premium</p>
                </div>
                <div>
                  <label className="block text-sm text-gray-600">Start Date</label>
                  <p>{DateFormate(subscriptionData.premium.startDate)}</p>
                </div>
                <div>
                  <label className="block text-sm text-gray-600">Expiry Date</label>
                  <p>{DateFormate(subscriptionData.premium.endDate)}</p>
                </div>
              </>
            )}

            {/* Display Common Subscription Details */}
            {(subscriptionData.freemium.active || subscriptionData.premium.active) && (
              <>
                <div>
                  <label className="block text-sm text-gray-600">Opportunities</label>
                  <p>{subscriptionData.opportunities}</p>
                </div>
                <div>
                  <label className="block text-sm text-gray-600">Search</label>
                  <p>{subscriptionData.search}</p>
                </div>
                <div>
                  <label className="block text-sm text-gray-600">Verified Application</label>
                  <p>{subscriptionData.verified_apply}</p>
                </div>
              </>
            )}

            {/* If no subscription is active, you can show this */}
            {!subscriptionData.freemium.active && !subscriptionData.premium.active && (
              <div>
                <p>No active subscription available.</p>
              </div>
            )}

          </div>
        </div>
        :
        <></>
        }
       
      </div>

      {
        detailExist ?
          <div className="mt-28 mb-24">
            <div className="flex justify-center">
              <div className="border-2 border-amber-500 w-full sm:w-[80%] md:w-[60%] lg:w-[40%] rounded-lg">
                <div className="flex flex-col sm:flex-row justify-center gap-10 p-2 sm:gap-8 md:gap-20">
                  <div className="p-2 flex justify-center">
                    <img
                      src={
                        userDetails && userDetails.profile_pic
                          ? userDetails.profile_pic
                          : "/dummy-profile-image.jpg"
                      }
                      alt="Profile"
                      className="rounded-full w-[6rem] h-[6rem] sm:w-[9rem] sm:h-[9rem] border-4 border-yellow-500"
                    />
                  </div>

                  {
                    !isEdit.personalInfo ?
                    <div className="personalInfoContainer flex flex-col justify-between items-center sm:items-start sm:relative sm:top-7 sm:right-4 ">
                    <h2 className="text-xl sm:text-2xl font-bold text-amber-500">
                      {userDetails &&
                        `${userDetails.firstName} ${userDetails.lastName}`}
                    </h2>
                    <div className="text-center sm:text-left">
                      <p className="text-gray-600">
                        Address: {userDetails && userDetails.permanentaddress}
                      </p>
                      <p className="text-gray-600">
                        Contact: {userDetails && userDetails.contact}
                      </p>
                      <div className="flex justify-center sm:justify-start">
                        <button onClick={()=>setIdEdit((prevData)=>({
                          ...prevData,
                          personalInfo:!prevData.personalInfo
                        }))} className="edit-btn bg-amber-500   p-2  cursor-pointer">
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                  :
                  
                  <>
                  <div className="editProfileInfo">
                  <div className="flex gap-1" >
                  <input type= 'text' onChange={changeHandler} name="firstName" value={userDetails.firstName}/> 
                  <input type= 'text' onChange={changeHandler} name="lastName" value={userDetails.lastName}/> 

                  </div>
                  <input type= 'text' onChange={changeHandler} name="permanentaddress" value={userDetails.permanentaddress} />
                  <input type= 'number' onChange={changeHandler} name="contact" value={userDetails.contact} />
                  <div className="flex justify-between">
                  <button onClick={()=>setIdEdit((prevData)=>({
                          ...prevData,
                          personalInfo:!prevData.personalInfo
                        }))} style={{background : 'gray' }}>cancel</button>
                  <button className="bg-amber-500" onClick={() => {
                      if (validateUserDetails()) {
                        editProfile(); 
                        setIdEdit(prevData => ({
                        ...prevData,
                        personalInfo: !prevData.personalInfo 
                        }));
                      }
                    }} 
                    style={{ background: 'amber-500' }}>
                  Submit
                  </button>
                  </div>
                
                  </div>
                  </>}


                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-center gap-4 sm:gap-7 mt-4">
              <div className="border-2 border-amber-500 w-full sm:w-[40%] md:w-[19%] rounded-lg p-2">
                <div className="p-2 text-center relative top-3">
                  <h1 className="text-lg sm:text-xl font-bold mb-2 sm:mb-0 text-center sm:text-left">Resume</h1>
                  <p className="text-amber-500 underline text-lg sm:text-xl p-2 hover:text-black cursor-pointer">
                    {userDetails && userDetails.student_PDF && (
                      <a
                        href={userDetails.student_PDF}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-black"
                      >
                        View Resume
                      </a>
                    )}
                  </p>
                </div>
              </div>


              {!isEdit.experience ? (
        <div className="border-2 border-amber-500 w-full sm:w-[40%] md:w-[19%] rounded-lg p-2">
          <div className="text-center relative top-2">
            <p className="text-lg sm:text-xl font-bold mb-2 sm:mb-0 text-center sm:text-left">Experience</p>
            <div className="text-gray-700">
              {userDetails.experience ? (
                <p>{userDetails.experience}</p>
              ) : (
                <p>No experience data available</p>
              )}
              <div className="flex justify-center sm:justify-start">
                <button
                  onClick={() => setIdEdit((prevData) => ({
                    ...prevData,
                    experience: !prevData.experience,
                  }))}
                  className="edit-btn bg-amber-500 text-amber-500 p-2 cursor-pointer"
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="experienceContainer border-2 border-amber-500 w-full sm:w-[40%] md:w-[19%] rounded-lg p-2">
          <input
            type='text'
            name="experience"
            onChange={changeHandler}
            value={userDetails.experience}
            placeholder="Enter your experience"
          />
          <div className="experienceContainer flex justify-between">
            <button
              onClick={() => setIdEdit((prevData) => ({
                ...prevData,
                experience: !prevData.experience,
              }))}
              style={{ background: 'gray' }}
            >
              Cancel
            </button>
            <button className="bg-amber-500" onClick={() => {
                      if (validateUserDetails()) {
                        editProfile(); // Call the editProfile function
                        setIdEdit(prevData => ({
                        ...prevData,
                        experience: !prevData.experience // Toggle personalInfo
                        }));
                      }
                    }} 
                    style={{ background: 'amber-500' }}>
                  Submit
                  </button>
          </div>
        </div>
      )}
              

            </div>


            <div className="flex justify-center mt-4">
      {!isEdit.keySkills ? (
        <div className="border-2 border-amber-500 w-full sm:w-[80%] md:w-[60%] lg:w-[40%] rounded-lg p-4 flex flex-col items-center sm:flex-row sm:justify-between">
          <p className="text-lg sm:text-xl font-bold mb-2 sm:mb-0 text-center sm:text-left">Skills</p>
          <div className="flex flex-col items-center sm:flex-row sm:gap-4 sm:items-start">
            {userDetails.keySkills && typeof userDetails.keySkills === "string" ? (
              userDetails.keySkills.split(",").map((keySkills, index) => (
                <div key={index} className="text-gray-700 mb-1 sm:mb-0 text-center sm:text-left">
                  {keySkills.trim()}
                </div>
              ))
            ) : (
              <p className="text-gray-700 text-center sm:text-left">No skills available</p>
            )}
          </div>
          <button onClick={() => setIdEdit((prevData) => ({
                    ...prevData,
                    keySkills: !prevData.keySkills,
                  }))}
                  className="edit-btn bg-amber-500 text-amber-500 p-2 cursor-pointer" >
            Edit
          </button>
        </div>
      ) : (
        <div className="skillsContainer border-2 border-amber-500 w-full sm:w-[80%] md:w-[60%] lg:w-[40%] rounded-lg p-4 flex flex-col items-center sm:flex-row sm:justify-between">
          <input
            type="text"
            placeholder="skills (comma separated)"
            value={userDetails.keySkills}
            onChange={changeHandler2}
            className="p-2 border rounded-md"
          />
          <div style={{ display: "flex", paddingLeft: "5px", gap: "5px" }}>
            <button onClick={toggleEdit} style={{ background: "gray" }}>Cancel</button>
            <button className="bg-amber-500" onClick={() => {
                      if (validateUserDetails()) {
                        editProfile(); // Call the editProfile function
                        setIdEdit(prevData => ({
                        ...prevData,
                        keySkills: !prevData.keySkills // Toggle personalInfo
                        }));
                      }
                    }} 
                    style={{ background: 'amber-500' }}>
                  Submit
                  </button>
          </div>
        </div>
      )}
    </div>

            

    <div className="flex flex-col sm:flex-row justify-center mt-4 gap-8">
      {/* Project Details */}
      {!isEdit.projectDetails ? (
        <div className="border-2 border-amber-500 w-full sm:w-[40%] md:w-[19%] rounded-lg p-4">
          <p className="text-lg sm:text-xl font-bold mb-2 text-center sm:text-left">
            Project Details
          </p>
          <div className="text-center sm:text-left">
            {userDetails.projectName ? (
              <>
                <p><strong>Project Name</strong>: {userDetails.projectName}</p>
                <p><strong>Project Summary</strong>: {userDetails.projectSummary}</p>
              </>
            ) : (
              <p>No project details available</p>
            )}
          </div>
          <button
            onClick={() => toggleEdit('projectDetails')}
            className="edit-btn bg-amber-500 p-2 cursor-pointer"
          >
            Edit
          </button>
        </div>
      ) : (
        <div className="projectDetailsContainer border-2 border-amber-500 w-full sm:w-[40%] md:w-[19%] rounded-lg p-4">
          <label htmlFor="Project-Name"><strong>Project Name:</strong></label>
          <input
            type="text"
            id="Project-Name"
            name="projectName"
            value={userDetails.projectName}
            onChange={changeHandler}
          />
          <label htmlFor="Project-Summary"><strong>Project Summary:</strong></label>
          <input
            type="text"
            name="projectSummary"
            value={userDetails.projectSummary}
            onChange={changeHandler}
          />
          <div className="flex justify-between mt-2">
            <button
              onClick={() => toggleEdit('projectDetails')}
              style={{ background: 'gray' }}
            >
              Cancel
            </button>
            <button className="bg-amber-500" onClick={() => {
                      if (validateUserDetails()) {
                        editProfile(); // Call the editProfile function
                        setIdEdit(prevData => ({
                        ...prevData,
                        projectDetails: !prevData.projectDetails // Toggle personalInfo
                        }));
                      }
                    }} 
                    style={{ background: 'amber-500' }}>
                  Submit
                  </button>
          </div>
        </div>
      )}

      {/* Education Details */}
      <div className="border-2 border-amber-500 w-full sm:w-[40%] md:w-[19%] rounded-lg p-4">
        <p className="text-lg sm:text-xl font-bold mb-2 text-center sm:text-left">
          Education Details
        </p>
        {!isEdit.educatinalDetails ? (
          <>
            <div className="text-center sm:text-left">
              {userDetails.education ? (
                <>
                  <p><strong>Education Level</strong>: {userDetails.education}</p>
                  <p><strong>Institute Name</strong>: {userDetails.instituteName}</p>
                  <p><strong>Stream</strong>: {userDetails.stream}</p>
                  <p><strong>Pass Out Year</strong>: {userDetails.passOutYear}</p>
                  <p><strong>Percentage/CGPA</strong>: {userDetails.percentage}</p>
                </>
              ) : (
                <p>No education details available</p>
              )}
            </div>
            <button
              onClick={() => toggleEdit('educatinalDetails')}
              className="edit-btn bg-amber-500 p-2 cursor-pointer"
            >
              Edit
            </button>
          </>
        ) : (
          <div className="educationalDetails flex flex-col">
            <input
              type='text'
              value={userDetails.education}
              name='education'
              onChange={changeHandler}
              placeholder="Educational Level"
              className="mb-2 p-2 border rounded-md"
            />
            <input
              type='text'
              value={userDetails.instituteName}
              name='instituteName'
              onChange={changeHandler}
              placeholder="Institute Name"
              className="mb-2 p-2 border rounded-md"
            />
            <input
              type='text'
              value={userDetails.stream}
              name='stream'
              onChange={changeHandler}
              placeholder="Stream"
              className="mb-2 p-2 border rounded-md"
            />
            <input
              type='text'
              value={userDetails.passOutYear}
              name='passOutYear'
              onChange={changeHandler}
              placeholder="Pass Out Year"
              className="mb-2 p-2 border rounded-md"
            />
            <input
              type='text'
              value={userDetails.percentage}
              name='percentage'
              onChange={changeHandler}
              placeholder="Percentage/CGPA"
              className="mb-2 p-2 border rounded-md"
            />
            <div className="flex justify-between mt-2">
              <button
                onClick={() => toggleEdit('educatinalDetails')}
                style={{ background: 'gray' }}
              >
                Cancel
              </button>
              <button className="bg-amber-500" onClick={() => {
                      if (validateUserDetails()) {
                        editProfile(); // Call the editProfile function
                        setIdEdit(prevData => ({
                        ...prevData,
                        educatinalDetails: !prevData.educatinalDetails // Toggle personalInfo
                        }));
                      }
                    }} 
                    style={{ background: 'amber-500' }}>
                  Submit
                  </button>
            </div>
          </div>
        )}
      </div>
    </div>


    <div className="flex justify-center mt-4 s:m-4 md:m-4 lg:m-4">
  <div className="border-2 border-amber-500 w-full sm:w-[80%] md:w-[60%] lg:w-[40%] rounded-lg p-4 items-center sm:flex-row sm:justify-between">
    {!isEdit.personalDetails ? (
      <>
        <p className="text-lg sm:text-xl font-bold mb-2 sm:mb-0 text-center sm:text-left">Personal Details</p>
        <div className="items-center sm:flex-row sm:gap-4 sm:items-start">
          {userDetails && userDetails.languages ? (
            <>
              <p><strong>Languages</strong>: {userDetails.languages}</p>
              <p><strong>Permanent Address</strong>: {userDetails.permanentaddress}</p>
              <p><strong>City</strong>: {userDetails.city}</p>
              <p><strong>District</strong>: {userDetails.district}</p>
              <p><strong>Birth Date</strong>: {new Date(userDetails.birthdate).toISOString().split('T')[0]}</p>
            </>
          ) : (
            <p>No personal details available</p>
          )}
        </div>
        <button onClick={() => setIdEdit((prevData) => ({
          ...prevData,
          personalDetails: !prevData.personalDetails
        }))} className="edit-btn bg-amber-500 p-2 cursor-pointer">
          Edit
        </button>
      </>
    ) : (
      <>
        <div className="personalDetailsContainer flex justify-between">
          <input type='text' value={userDetails.languages} name='languages' onChange={changeHandler} placeholder="languages" />
          <input type='text' value={userDetails.permanentaddress} name='permanentaddress' onChange={changeHandler} placeholder="permanentaddress" />
          <input type='text' value={userDetails.city} name='city' onChange={changeHandler} placeholder="city" />
          <input type='text' value={userDetails.district} name='district' onChange={changeHandler} placeholder="district" />

          {/* Restrict birthdate to past dates only */}
          <input 
            type='date' 
            value={new Date(userDetails.birthdate).toISOString().split('T')[0]} 
            name='birthdate' 
            onChange={changeHandler} 
            placeholder="birthdate"
            max={new Date().toISOString().split('T')[0]}  // This sets today's date as the max
          />

          {errors.lastName && <div className="text-red-500">{errors.lastName}</div>}
          <div className="flex justify-between">
            <button onClick={() => setIdEdit((prevData) => ({
              ...prevData,
              personalDetails: !prevData.personalDetails
            }))} style={{ background: 'gray' }}>Cancel</button>
            <button className="bg-amber-500" onClick={() => {
                      if (validateUserDetails()) {
                        editProfile(); // Call the editProfile function
                        setIdEdit(prevData => ({
                        ...prevData,
                        personalDetails: !prevData.personalDetails // Toggle personalInfo
                        }));
                      }
                    }} 
                    style={{ background: 'amber-500' }}>
                  Submit
                  </button>
          </div>
        </div>
      </>
    )}
  </div>
</div>



          </div>
          :
          <div className="text-3xl font-semibold text-center my-3">
            Details are not filled.
          </div>
      }

      <div>
        <Footer />
      </div>
    </>
  );
};

export default ViewProfile;
